import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import appRoutes from "./configs/routes";
import LoadingModal from "./components/Loadings";
import { Helmet } from 'react-helmet';

function App() {
  const routes = useRoutes(appRoutes);

  return <Suspense fallback={<LoadingModal open={true} />}>
    <Helmet>
      <script type='text/javascript' src={`${process.env.REACT_APP_MIDTRANS_URL}`} data-client-key={`${process.env.REACT_APP_MIDTRANS_KEY}`} />
    </Helmet>
    {routes}
  </Suspense>;
}

export default App;