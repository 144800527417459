import { lazy } from "react";
import Loadable from "../../components/Loadings/Loadable";
import { AGENDA_PAGE, BILLING_PAGE, CLINIC_PAGE, DOCTOR_PAGE, HOME_PAGE, PATIENTS_PAGE, PAYMENT_PAGE, TREATMENT_PAGE } from "../../constants/RoutesUrls";

// Layout
const MainLayout = Loadable(lazy(() => import("../../components/Layouts/MainLayout")));
const AuthLayout = Loadable(lazy(() => import("../../components/Layouts/AuthLayout")));

// Auths
const SignInPage = Loadable(lazy(() => import("../../screens/auths/SignIn")));
const RegisterPage = Loadable(lazy(() => import("../../screens/auths/Register")));

//Home
const HomePage = Loadable(lazy(() => import("../../screens/mains/home")));

//Patients
const PatientsPage = Loadable(
  lazy(() => import("../../screens/mains/patients"))
);
const AddPatientsPage = Loadable(
  lazy(() => import("../../screens/mains/patients/create"))
);
const DetailPatientPage = Loadable(
  lazy(() => import("../../screens/mains/patients/fragments/detail_patient"))
);

//Create
const ExaminationCreateUpdateSection = Loadable(
  lazy(() => import("../../screens/mains/patients/fragments/detail_patient/ExaminationCreateUpdateSection"))
);

const CreateUpdatePaymentPage = Loadable(
  lazy(() => import("../../screens/mains/patients/fragments/detail_patient/PaymentCreateUpdate"))
);

//Payment
const PaymentPage = Loadable(lazy(() => import("../../screens/mains/patients/payment")));

//Agenda
const AgendaPage = Loadable(lazy(() => import("../../screens/mains/agenda")));

// Setting Menu
const ClinicPage = Loadable(lazy(() => import("../../screens/mains/profile")));

const TreatmentPage = Loadable(
  lazy(() => import("../../screens/mains/treatment"))
);

const DoctorPage = Loadable(
  lazy(() => import("../../screens/mains/doctor"))
);

const BillingPage = Loadable(
  lazy(() => import("../../screens/billings"))
);

const SuccessPaymentPage = Loadable(
  lazy(() => import("../../screens/payments/Success"))
);

const ErrorPaymentPage = Loadable(
  lazy(() => import("../../screens/payments/Error"))
);

const PendingPaymentPage = Loadable(
  lazy(() => import("../../screens/payments/Pending"))
);

const RedirectPage = Loadable(
  lazy(() => import("../../screens/redirect"))
);

const appRoutes = [
  {
    path: "auths",
    element: <AuthLayout />,
    children: [
      { path: "sign-in", element: <SignInPage /> },
      { path: "register", element: <RegisterPage /> },
    ],
  },
  {
    path: HOME_PAGE,
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
    ],
  },
  {
    path: PATIENTS_PAGE,
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <PatientsPage />,
      },
      {
        path: "create",
        element: <AddPatientsPage />,
      },
      {
        path: "examination",
        element: <ExaminationCreateUpdateSection />,
      },
      {
        path: "examination/:id",
        element: <ExaminationCreateUpdateSection />,
      },
      {
        path: "payment",
        element: <PaymentPage />,
      },
      {
        path: "payment/form",
        element: <CreateUpdatePaymentPage />,
      },
      {
        path: "payment/form/:id",
        element: <CreateUpdatePaymentPage />,
      },
      {
        path: ":id",
        element: <DetailPatientPage />,
      },
    ],
  },
  {
    path: AGENDA_PAGE,
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <AgendaPage />,
      },
    ],
  },
  {
    path: DOCTOR_PAGE,
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <DoctorPage />,
      },
    ],
  },
  {
    path: TREATMENT_PAGE,
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <TreatmentPage />,
      },
    ],
  },
  {
    path: BILLING_PAGE,
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <BillingPage />,
      },
    ],
  },
  {
    path: CLINIC_PAGE,
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <ClinicPage />,
      },
    ],
  },
  {
    path: '/payments',
    children: [
      {
        path: "pending",
        element: <PendingPaymentPage />,
      },
      {
        path: "failed",
        element: <ErrorPaymentPage />,
      },
      {
        path: "success",
        element: <SuccessPaymentPage />,
      },
    ],
  },
  {
    path: '*',
    element: <RedirectPage />
  }
];

export default appRoutes;
